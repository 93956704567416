import * as React from "react";

import "./SectionLanding.scss";

import { Parallax } from "react-scroll-parallax";
import { Link as AnchorLink } from "react-scroll";

import { anchorLinkProps } from "../../utils/anchorLinkProps";

import ScrollIcon from "./ScrollIcon/ScrollIcon";

export default function SectionLanding(props) {
	return (
		<div className={`sectionLandingWrapper ${props.className || ""}`}>
			<Parallax speed={20} className="parallax">
				<div className="sectionLanding">
					<div>
						<h2>{props.orbitalText}</h2>

						<AnchorLink
							{...anchorLinkProps}
							offset={0}
							to="sectionContent"
							className="sectionLandingTitle"
						>
							<h1>{props.title}</h1>
						</AnchorLink>

						<h2>{props.subtitle}</h2>
					</div>
					{props.children}
					{!props.onlyLanding && !props.hideScroll && (
						<ScrollIcon scrollText={props.scrollText} />
					)}
				</div>
			</Parallax>
			<div id="sectionContent" />
		</div>
	);
}
