import "./contacto.scss";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import SectionLanding from "../../components/section/SectionLanding";
import ContactForm from "../../components/contact/ContactForm";

export default function IndexPage() {
	const { t } = useTranslation();

	return (
		<Layout className="invertColors noBg">
			<Seo title={t("Contacto")} />

			<div id="contacto" className="section splitSection">
				<div className="titleContainer">
					<StaticImage
						src={`../../images/contacto.jpg`}
						className="landing-bg"
						alt="Section background"
						layout="fullWidth"
					/>

					<div className="landingSection">
						<SectionLanding
							title={
								<Trans i18nKey="titleContacto">
									Hola, <i>¿qué tal?</i>
								</Trans>
							}
							orbitalText={t("Contacto")}
						/>
					</div>
				</div>
				<div className="contentsContainer section invertColors">
					<div>
						<p className="h2">
							<Trans>
								Imaginamos que tienes alguna duda, quieres aportar algo para que
								podamos mejorar o sencillamente quieres comentarnos cualquier
								otra cosa. Sea lo que sea, estamos encantados de escucharte.
							</Trans>
						</p>
						<p className="h2">
							<Trans>
								También puedes consultar nuestro apartado de{" "}
								<Link to="/legal/FAQ/">preguntas frecuentes</Link>
							</Trans>
						</p>
						<ContactForm />
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
