import "./ScrollIcon.css";

import React from "react";

import { Link as AnchorLink } from "react-scroll";

// util imports
import { anchorLinkProps } from "../../../utils/anchorLinkProps";

import IconDesplegable from "../../../svg/icons/iconDesplegable.svg";

export default function ScrollIcon({ scrollText }) {
	return (
		<>
			<div id="iconScroll">
				<AnchorLink {...anchorLinkProps} offset={0} to="sectionContent">
					{scrollText ? <h3>{scrollText}</h3> : null}
					<IconDesplegable />
				</AnchorLink>
			</div>
		</>
	);
}
