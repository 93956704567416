import "./ContactForm.scss";

import React from "react";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const FORM_ENDPOINT = "";

export default function ContactForm() {
	const { restaurants } = useStaticQuery(
		graphql`
			query {
				restaurants: allMarkdownRemark {
					nodes {
						frontmatter {
							simpleName
						}
						fields {
							language
						}
					}
				}
			}
		`
	);

	const { t } = useTranslation();
	const { language } = useI18next();

	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_email: "",
		name: "",
		phone: "",
		subject: "",
		comment: "",
		section: "Contacto",
		data_protection: "No",
		com_comercial: "No",
	});
	// console.log(toSend);

	const [formSent, setFormSent] = React.useState(false);
	const [formError, setFormError] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(false);
		setLoading(true);

		axios({
			method: "post",
			// url: "https://peim.es/php_test/begin/envioContacto.php", // for testing purposes
			url: withPrefix("/php/envioContacto.php"),

			data: {
				...toSend,
			},
		})
			.then((res) => {
				setLoading(false);

				setFormSent(true);
				// console.log(res);
			})
			.catch((err) => {
				setLoading(false);
				setFormError(true);
				console.log(err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	return (
		<div>
			<SwitchTransition mode="out-in">
				<CSSTransition key={formSent} timeout={400} classNames="fade">
					<>
						{!formSent ? (
							<div>
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									onChange={handleChange}
									method="POST"
									target="_blank"
									className="contactForm"
									id="contactoForm"
								>
									<div className="inputContainer">
										<div className="leftNum h2">01/</div>
										<input
											type="text"
											placeholder={t("Nombre")}
											id="name"
											name="name"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">02/</div>
										<input
											type="email"
											placeholder={t("Email")}
											id="from_email"
											name="from_email"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">03/</div>
										<input
											type="tel"
											placeholder={t("Teléfono")}
											id="phone"
											name="phone"
											title=" "
											required
										/>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">04/</div>
										<div className="selectContainer">
											<select
												id="subject"
												name="subject"
												placeholder={t("¿Sobre qué es tu consulta?")}
												required
												className="p1 formel"
												defaultValue={""}
											>
												<option value="" disabled>
													{t("¿Sobre qué es tu consulta?")}
												</option>
												{restaurants?.nodes
													?.filter(
														(restaurant) =>
															restaurant.fields.language === language
													)
													?.map((restaurant) => {
														return (
															<option
																value={restaurant.frontmatter.simpleName}
																key={restaurant.frontmatter.simpleName}
															>
																Begin {restaurant.frontmatter.simpleName}
															</option>
														);
													})}
												<option value="Otro tema">{t("Otro tema")}</option>
											</select>
										</div>
									</div>

									<div className="inputContainer">
										<div className="leftNum h2">05/</div>
										<TextareaAutosize
											placeholder={t("Mensaje")}
											id="comment"
											name="comment"
											title=" "
											required
											value={toSend.comment}
											maxRows={8}
											minRows={1}
										/>
									</div>

									<div className="dataProtection">
										<div className="dataCheck">
											<input
												type="checkbox"
												name="data_protection"
												id="data_protection"
												required
											/>
											<label
												htmlFor="data_protection"
												aria-label={t(
													"Consiento el uso de mis datos personales para que atiendan mi solicitud"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento el uso de mis datos personales para que
														atiendan mi solicitud, según lo establecido en su{" "}
														<a
															href="/legal/politica-privacidad"
															target="_blank"
															rel="noopener noreferrer"
														>
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</a>{" "}
														y declaro tener cumplidos los catorce (14) años de
														edad, asumiendo las posibles responsabilidades
														legales sobre la veracidad de esta declaración.
													</Trans>
												</p>
											</label>
										</div>

										<div className="dataCheck">
											<input
												type="checkbox"
												name="com_comercial"
												id="com_comercial"
											/>
											<label
												htmlFor="com_comercial"
												aria-label={t(
													"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento la recepción de comunicaciones del
														restaurante por e-mail y/o SMS con fines comerciales
													</Trans>
												</p>
											</label>
										</div>
									</div>

									<div className="formSubmit">
										<button
											type="submit"
											aria-label={t("Enviar")}
											className="roundButton"
										>
											{loading && !formError ? (
												<Trans>Enviando...</Trans>
											) : (
												<Trans>Enviar</Trans>
											)}
										</button>
										<div className="dataProtection">
											<p>
												<Trans ns="dataProtection">
													Acepto las{" "}
													<a
														href="/legal/politica-privacidad"
														target="_blank"
														rel="noopener noreferrer"
													>
														condiciones de uso, política de privacidad
													</a>
													<a
														href="/legal/aviso-legal/"
														target="_blank"
														rel="noopener noreferrer"
													>
														y aviso legal
													</a>
												</Trans>
											</p>
										</div>
										{formError && (
											<div>
												<p>
													<Trans>
														¡Ups! Ha habido un error en el envío del mensaje.
														¡Lo sentimos!
													</Trans>
												</p>
												<p>
													<Trans>
														Vuelve a intentarlo en unos momentos. Si el problema
														persiste, también puedes contactarnos a través del
														chat o de nuestras Redes Sociales:
													</Trans>
												</p>
											</div>
										)}
									</div>

									<div className="dataProtection">
										<p>
											<Trans ns="dataProtection">
												Información básica sobre protección de datos
											</Trans>
											:
										</p>
										<table>
											<tbody>
												<tr>
													<td>
														<Trans ns="dataProtection">Responsable</Trans>:
													</td>
													<td>BEGIN RESTAURANTES, S.L.</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Domicilio</Trans>:
													</td>
													<td>
														Av. Cortes Valencianas, 50, CP 46015 Valencia
														(Valencia)
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Finalidad</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Atender su solicitud de información
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Legitimacion</Trans>
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos serán tratados solo con su
															consentimiento, al marcar la casilla mostrada en
															este formulario
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Destinatarios</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos no serán cedidos a terceros
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Derechos</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Tiene derecho a solicitarnos acceder a sus datos,
															corregirlos o eliminarlos, también puede
															solicitarnos limitar su tratamiento, oponerse a
															ello y a la portabilidad de sus datos,
															dirigiéndose a nuestra dirección postal o a
														</Trans>{" "}
														<a href="mailto:contacto@beginrestaurante.com">
															contacto@beginrestaurante.com
														</a>
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Mas info</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Dispone de más información en nuestra
														</Trans>{" "}
														<Link to="/legal/pollitica-privacidad">
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</form>
							</div>
						) : (
							<div>
								<p>
									<Trans>
										Solicitud recibida. Pronto nos pondremos en contacto
										contigo.
									</Trans>
								</p>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
